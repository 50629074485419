export const TestId = {
  APP: 'APP',
  SIDEBAR: 'SIDEBAR',
  SNACKBAR_POPUP: 'SNACKBAR_POPUP',
  SIDEBAR_DASHBOARD_LINK: 'SIDEBAR_DASHBOARD_LINK',
  HEADER_LANGUAGE_SWITCH_BUTTON: 'HEADER_LANGUAGE_SWITCH_BUTTON',
  HEADER_LANGUAGE_SWITCH_POPUP: 'HEADER_LANGUAGE_SWITCH_POPUP',
  ADD_LEASE_COMPANY_BUTTON: 'ADD_LEASE_COMPANY_BUTTON',
  ADD_CONCEPT_COMPANY_BUTTON: 'ADD_CONCEPT_COMPANY_BUTTON',
  CREATE_LEASE_COMPANY_FORM: 'CREATE_LEASE_COMPANY_FORM',
  CREATE_CONCEPT_COMPANY_FORM: 'CREATE_CONCEPT_COMPANY_FORM',
  ADD_COMPANY_NAME_INPUT: 'ADD_COMPANY_NAME_INPUT',
  ADD_COMPANY_ID_INPUT: 'ADD_COMPANY_ID_INPUT',
  EDIT_BUTTON: 'EDIT_BUTTON',
  BACK_BUTTON: 'BACK_BUTTON',
  SAVE_BUTTON: 'SAVE_BUTTON',
  SAVE_AND_CLOSE_BUTTON: 'SAVE_AND_CLOSE_BUTTON',
  ENABLE_EMPLOYEE_BUTTON: 'ENABLE_EMPLOYEE_BUTTON',
  DISABLE_EMPLOYEE_BUTTON: 'DISABLE_EMPLOYEE_BUTTON',
  DELETE_EMPLOYEE_BUTTON: 'DELETE_EMPLOYEE_BUTTON',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  EMPLOYEE_FIRST_NAME: 'EMPLOYEE_FIRST_NAME',
  EMPLOYEE_LAST_NAME: 'EMPLOYEE_LAST_NAME',
  EMPLOYEE_NAME_PREFIX: 'EMPLOYEE_NAME_PREFIX',
  EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
};
