import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getCompanyDto,
  getCompanyDtoError,
  getCompanyDtoStatus,
  handleEditCompanyIdChange,
  handleEditCompanyNameChange,
} from '../../features/company_table/slices/getAndUpdateCompanySlice'
import { StatusEnum } from '../../features/types/shared/status'
import EmployeeTable from '../employeeTable'
import CustomButton from '../shared/custom_button'
import CustomInput from '../shared/custom_input'
import Loader from '../shared/loader'
import { RowStart } from '../shared/row'
import { ErrorContainer } from '../shared/error_container';
import { TestId } from '../../constants/testIds'
import useErrorMessage from '../../hooks/useErrorMessage'

export default function EditCompanyDetails() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const companyDto = useAppSelector(getCompanyDto)
  const getCompanyStatus = useAppSelector(getCompanyDtoStatus)
  const getCompanyError = useAppSelector(getCompanyDtoError)
  const errorMessage = useErrorMessage(getCompanyError);

  if (getCompanyStatus === StatusEnum.ERROR) {
    return <ErrorContainer>{errorMessage}</ErrorContainer>;
  }

  const isLoading =
    getCompanyStatus === StatusEnum.PENDING ||
    getCompanyStatus === StatusEnum.INITIAL;

  if (isLoading) {
    return <Loader />;
  }

  if (getCompanyStatus === StatusEnum.LOADED) {
    return (
      <div>
        <RowStart>
          <CustomInput
            dataTestId={TestId.ADD_COMPANY_NAME_INPUT}
            value={companyDto?.name || ''}
            onInput={(val) => dispatch(handleEditCompanyNameChange(val))}
            label={t('general.company_name')}
            placeholder={t('general.company_name')}
            required
            className='generalCard_input'
          />
          <CustomInput
            dataTestId={TestId.ADD_COMPANY_ID_INPUT}
            value={companyDto?.externalId || ''}
            onInput={(val) => dispatch(handleEditCompanyIdChange(val))}
            label={t('general.id')}
            placeholder={t('general.id')}
            sx={{ width: 300 }}
            required
          />
        </RowStart>
        <div style={{ marginTop: '24px' }}>
          <CustomButton
            dataTestId={TestId.ADD_EMPLOYEE}
            label={t('general.add_employee')}
            onClick={() => navigate('createEmployee')}
          />
        </div>
        <EmployeeTable />
      </div>
    );
  }

  return <ErrorContainer>{t('management_table.error')}</ErrorContainer>;
}
