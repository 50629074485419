import { styled } from '@mui/material/styles'
import { baseWhite } from '../../style/colors';
import { APP_HEADER_HEIGHT } from './../../constants/general'

interface Props {
  transparent?: boolean;
}

export const MainContainer = styled('div')(({ transparent = false }: Props) => ({
  position: 'relative',
  padding: '15px 24px',
  height: `calc(100vh - ${APP_HEADER_HEIGHT}px - 16px - 50px)`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: transparent ? '' : baseWhite,
}))
