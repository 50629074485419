import { useTranslation } from 'react-i18next';

import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { useAppSelector } from '../../app/hooks';

import {
  getCompanyDto,
  getCompanyDtoStatus,
} from '../../features/company_table/slices/getAndUpdateCompanySlice';
import { StatusEnum } from '../../features/types/shared/status';
import { useBasePath } from '../../hooks/useBasePath';
import { basePaths, routesDefinitions } from '../../routes';
import Loader from '../shared/loader';
import { TextBold } from '../shared/text_bold';

const Breadcrumbs = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'header',
  });

  const basePath = useBasePath();

  const companyDto = useAppSelector(getCompanyDto);
  const companyDtoStatus = useAppSelector(getCompanyDtoStatus);

  if (basePath === routesDefinitions.dashboard) {
    return null;
  }

  const breadcrumbLabels = {
    [routesDefinitions.createLease]: 'new_lease_company',
    [routesDefinitions.createConcept]: 'new_concept_company',
    [routesDefinitions.editDashboard]: 'edit',
    [basePaths.createEmployee]: 'add_employee',
    [basePaths.editEmployee]: 'edit_employee',
  };

  const isCompanyDtoStatusLoaded = companyDtoStatus === StatusEnum.LOADED;

  const isEmployerPath =
    basePath &&
    [basePaths.createEmployee, basePaths.editEmployee].indexOf(basePath) !== -1;

  const breadcrumbLabel = breadcrumbLabels[basePath ?? ''];

  if (isEmployerPath && !isCompanyDtoStatusLoaded) {
    return <Loader width="30px" height="30px" />;
  }

  if (breadcrumbLabel) {
    return (
      <MuiBreadcrumbs
        separator={<NavigateNext fontSize='small' />}
        aria-label='breadcrumb'
      >
        <TextBold>{t('companies')}</TextBold>{' '}
        {isEmployerPath && <TextBold>{companyDto?.name}</TextBold>}
        <TextBold className='current_path_indicator'>
          {t(breadcrumbLabel)}
        </TextBold>
      </MuiBreadcrumbs>
    );
  }

  return null;
};

export default Breadcrumbs;
