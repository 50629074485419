import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { StatusEnum } from '../../types/shared/status';

import IGetAndUpdateEmployeeState from '../../types/employee/get_and_update_employee_state';
import {
  getEmployee,
  removeEmployee,
  updateEmployee,
} from '../services/employee_service';

const DEFUALT_ERROR = { message: '' };

export const initialState: IGetAndUpdateEmployeeState = {
  getEmployeeStatus: StatusEnum.INITIAL,
  updateEmployeeStatus: StatusEnum.INITIAL,
  employeeDto: null,
  initialFetchedEmployee: null,
  getEmployeeError: DEFUALT_ERROR,
  updateEmployeeError: DEFUALT_ERROR,
}

// TODO: update statuses for employee
// too many separate 'getEmployeeStatus' and 'updateEmployeeStatus'
export const getAndUpdateEmployeeSlice = createSlice({
  name: 'getAndUpdateEmployee',
  initialState,
  reducers: {
    handleFirstNameChange(
      state: IGetAndUpdateEmployeeState,
      action: PayloadAction<string>
    ) {
      state.employeeDto = {
        ...state.employeeDto,
        firstName: action.payload
      }
    },
    handleNamePrefixChange(
      state: IGetAndUpdateEmployeeState,
      action: PayloadAction<string>
    ) {
      state.employeeDto = {
        ...state.employeeDto,
        namePrefix: action.payload
      }
    },
    handleLastNameChange(
      state: IGetAndUpdateEmployeeState,
      action: PayloadAction<string>
    ) {
      state.employeeDto = {
        ...state.employeeDto,
        lastName: action.payload
      }
    },
    handleEmailChange(
      state: IGetAndUpdateEmployeeState,
      action: PayloadAction<string>
    ) {
      state.employeeDto = {
        ...state.employeeDto,
        email: action.payload
      }
    },
    handleLanguageChange(
      state: IGetAndUpdateEmployeeState,
      action: PayloadAction<string>
    ) {
      state.employeeDto = {
        ...state.employeeDto,
        language: action.payload
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getEmployee.fulfilled,
      (state: IGetAndUpdateEmployeeState, { payload }) => {
        state.employeeDto = payload;
        state.initialFetchedEmployee = payload;
        state.getEmployeeStatus = StatusEnum.LOADED;
        state.getEmployeeError.message = '';
      }
    );
    builder.addCase(
      getEmployee.pending,
      (state: IGetAndUpdateEmployeeState) => {
        state.getEmployeeStatus = StatusEnum.PENDING;
        state.getEmployeeError.message = '';
      }
    );
    builder.addCase(
      getEmployee.rejected,
      (state: IGetAndUpdateEmployeeState, { payload }) => {
        state.getEmployeeStatus = StatusEnum.ERROR;
        state.getEmployeeError = payload || initialState.getEmployeeError;
      }
    );
    builder.addCase(
      updateEmployee.fulfilled,
      (state: IGetAndUpdateEmployeeState) => {
        state.updateEmployeeStatus = StatusEnum.LOADED;
        state.updateEmployeeError.message = '';
      }
    );
    builder.addCase(
      updateEmployee.pending,
      (state: IGetAndUpdateEmployeeState) => {
        state.updateEmployeeStatus = StatusEnum.PENDING;
        state.updateEmployeeError.message = '';
      }
    );
    builder.addCase(
      updateEmployee.rejected,
      (state: IGetAndUpdateEmployeeState, { payload }) => {
        state.updateEmployeeStatus = StatusEnum.ERROR;
        state.updateEmployeeError = payload || initialState.updateEmployeeError;
    })
    builder.addCase(
      removeEmployee.fulfilled,
      (state: IGetAndUpdateEmployeeState) => {
        state.getEmployeeStatus = StatusEnum.LOADED;
        state.getEmployeeError = DEFUALT_ERROR;
        state.updateEmployeeStatus = StatusEnum.LOADED;
        state.updateEmployeeError = DEFUALT_ERROR;
      }
    );
    builder.addCase(
      removeEmployee.rejected,
      (state: IGetAndUpdateEmployeeState, { payload }) => {
        state.getEmployeeStatus = StatusEnum.ERROR;
        state.getEmployeeError = payload || DEFUALT_ERROR;
        state.updateEmployeeStatus = StatusEnum.ERROR;
        state.updateEmployeeError = payload || DEFUALT_ERROR;
      }
    );
  },
})

export const selectIsVerificationEmailSent = (state: RootState) =>
  state.getAndUpdateEmployee.employeeDto?.verificationEmailSent;
export const selectIsEmailVerified = (state: RootState) =>
  state.getAndUpdateEmployee.employeeDto?.isVerified;
export const getEmployeeDto = (state: RootState) =>
  state.getAndUpdateEmployee.employeeDto;
export const selectInitialEmployee = ({ getAndUpdateEmployee }: RootState) =>
  getAndUpdateEmployee.initialFetchedEmployee;
export const getEmployeeDtoStatus = (state: RootState) =>
  state.getAndUpdateEmployee.getEmployeeStatus;
export const updateEmployeeDtoStatus = (state: RootState) =>
  state.getAndUpdateEmployee.updateEmployeeStatus;
export const getEmployeeDtoError = ({ getAndUpdateEmployee }: RootState) =>
  getAndUpdateEmployee.getEmployeeError;
export const updateEmployeeDtoError = ({ getAndUpdateEmployee }: RootState) =>
  getAndUpdateEmployee.updateEmployeeError;

export const {
  handleFirstNameChange,
  handleNamePrefixChange,
  handleLastNameChange,
  handleEmailChange,
  handleLanguageChange,
} = getAndUpdateEmployeeSlice.actions;

export default getAndUpdateEmployeeSlice.reducer;
