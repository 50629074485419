import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { StatusEnum } from '../../types/shared/status';

import IGetAndUpdateCompanyState from '../../types/company/get_and_update_company_state';
import {
  getCompany,
  getCompanyList,
  updateCompany,
} from '../services/company_service';

export const initialState: IGetAndUpdateCompanyState = {
  getCompanyStatus: StatusEnum.INITIAL,
  updateCompanyStatus: StatusEnum.INITIAL,
  companyDto: null,
  initialFetchedCompany: null,
  getCompanyError: { message: '' },
  updateCompanyError: { message: '' },
}

export const getAndUpdateCompanySlice = createSlice({
  name: 'getAndUpdateCompany',
  initialState,
  reducers: {
    handleEditCompanyNameChange(
      state: IGetAndUpdateCompanyState,
      action: PayloadAction<string>
    ) {
      state.companyDto = {
        ...state.companyDto,
        name: action.payload,
      };
    },
    handleEditCompanyIdChange(
      state: IGetAndUpdateCompanyState,
      action: PayloadAction<string>
    ) {
      state.companyDto = {
        ...state.companyDto,
        externalId: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getCompanyList.fulfilled,
      (state: IGetAndUpdateCompanyState) => {
        state.updateCompanyError = initialState.updateCompanyError;
      }
    );
    builder.addCase(
      getCompany.fulfilled,
      (state: IGetAndUpdateCompanyState, { payload }) => {
        state.companyDto = payload;
        state.initialFetchedCompany = payload;
        state.getCompanyStatus = StatusEnum.LOADED;
        state.getCompanyError = initialState.getCompanyError;
      }
    );
    builder.addCase(getCompany.pending, (state: IGetAndUpdateCompanyState) => {
      state.getCompanyStatus = StatusEnum.PENDING;
      state.getCompanyError = initialState.getCompanyError;
    });
    builder.addCase(
      getCompany.rejected,
      (state: IGetAndUpdateCompanyState, { payload }) => {
        state.getCompanyStatus = StatusEnum.ERROR;
        state.getCompanyError = payload || initialState.getCompanyError;
      }
    );
    builder.addCase(
      updateCompany.fulfilled,
      (state: IGetAndUpdateCompanyState) => {
        state.updateCompanyStatus = StatusEnum.LOADED;
        state.updateCompanyError = initialState.updateCompanyError;
      }
    );
    builder.addCase(
      updateCompany.pending,
      (state: IGetAndUpdateCompanyState) => {
        state.updateCompanyStatus = StatusEnum.PENDING;
        state.updateCompanyError = initialState.updateCompanyError;
      }
    );
    builder.addCase(
      updateCompany.rejected,
      (state: IGetAndUpdateCompanyState, { payload }) => {
        state.updateCompanyStatus = StatusEnum.ERROR;
        state.updateCompanyError = payload || initialState.updateCompanyError;
      }
    );
  },
})

export const getCompanyDto = (state: RootState) => state.editAndUpdateCompany.companyDto
export const selectCompanyId = ({ editAndUpdateCompany }: RootState) => editAndUpdateCompany.companyDto?.id;
export const selectInitialCompany = ({ editAndUpdateCompany }: RootState) => editAndUpdateCompany.initialFetchedCompany;
export const getCompanyDtoStatus = (state: RootState) => state.editAndUpdateCompany.getCompanyStatus
export const updateCompanyDtoStatus = (state: RootState) => state.editAndUpdateCompany.updateCompanyStatus
export const getCompanyDtoError = (state: RootState) => state.editAndUpdateCompany.getCompanyError
export const updateCompanyDtoError = (state: RootState) => state.editAndUpdateCompany.updateCompanyError

export const { handleEditCompanyNameChange, handleEditCompanyIdChange } = getAndUpdateCompanySlice.actions
export default getAndUpdateCompanySlice.reducer
