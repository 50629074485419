import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import CustomButton, { CustomButtonProps } from '../shared/custom_button';
import CustomInput from '../shared/custom_input';

import { baseWhite, borderGray, iconsGray } from '../../style/colors';

export const StyledSearchInput = styled(CustomInput)({
  boxSizing: 'border-box',
  width: '100%',
  height: '40px',
  background: baseWhite,
  borderRadius: '4px',
  order: 0,
  '.MuiInputBase-root': {
    width: 'inherit',
  }
});


const SearchButton = styled(CustomButton)({
  color: iconsGray,
  padding: '10px 15px',
  maxHeight: '40px',
  background: borderGray,
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    color: baseWhite,
  },
});

export const StyledSearchButton = (props: CustomButtonProps) => (
  <SearchButton {...props} startIcon={<SearchIcon />} />
);
