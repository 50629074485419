import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { TestId } from '../../constants/testIds'
import {
  getCreateEmployeeDto,
  getCreateEmployeeError,
  handleCreateEmployeeEmailChange,
  handleCreateEmployeeFirstNameChange,
  handleCreateEmployeeLastNameChange,
  handleCreateEmployeeNamePrefixChange,
} from '../../features/employee/slices/createEmployeeSlice'
import CustomInput from '../shared/custom_input'
import { ErrorContainer } from '../shared/error_container'
import { RowStart } from '../shared/row'
import useErrorMessage from '../../hooks/useErrorMessage'

export default function CreateEmployeeForm() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const createEmployeeDto = useAppSelector(getCreateEmployeeDto)
  const createEmployeeError = useAppSelector(getCreateEmployeeError)
  const errorMessage = useErrorMessage(createEmployeeError);

  return (
    <div style={{ marginTop: '24px' }}>
      <RowStart>
        <CustomInput
          dataTestId={TestId.EMPLOYEE_FIRST_NAME}
          value={createEmployeeDto.firstName}
          className='generalCard_input'
          label={t('general.first_name')}
          required
          onInput={(val) => dispatch(handleCreateEmployeeFirstNameChange(val))}
        />
        <CustomInput
          dataTestId={TestId.EMPLOYEE_NAME_PREFIX}
          value={createEmployeeDto.namePrefix}
          className='generalCard_input'
          label={t('general.name_prefix')}
          onInput={(val) => dispatch(handleCreateEmployeeNamePrefixChange(val))}
        />
        <CustomInput
          dataTestId={TestId.EMPLOYEE_LAST_NAME}
          value={createEmployeeDto.lastName}
          className='generalCard_input'
          label={t('general.last_name')}
          required
          onInput={(val) => dispatch(handleCreateEmployeeLastNameChange(val))}
        />
        <CustomInput
          sx={{ width: 300 }}
          dataTestId={TestId.EMPLOYEE_EMAIL}
          value={createEmployeeDto.email}
          label={t('general.email_address_business')}
          required
          onInput={(val) => dispatch(handleCreateEmployeeEmailChange(val))}
        />
      </RowStart>
      <ErrorContainer>{errorMessage}</ErrorContainer>
    </div>
  )
}
