import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  getCreateEmployeeDto,
  handleCreateEmployeeEmailChange,
  handleCreateEmployeeLanguageSelect,
} from '../../../features/employee/slices/createEmployeeSlice';
import {
  getEmployeeDto,
  handleLanguageChange,
} from '../../../features/employee/slices/getAndUpdateEmployee';

import CustomInput from '../../shared/custom_input';
import { RowStart } from '../../shared/row';

import { SELECT_LANGUAGE_OPTIONS } from '../../../constants/general';

const StyledBox = styled(Box)({
  marginTop: '24px',
  display: 'flex',

  '.MuiInputBase-root': {
    height: '44px',
  },

  '.select-language-drop-down.MuiAutocomplete-root': {
    width: 300,
    height: '44px',
    marginRight: '28px',

    'input.MuiAutocomplete-input': {
      padding: '0 0 0 6px',
    },
  },
});

export default function EmployeeTabContent({
  employeeId,
}: {
  employeeId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectEmployee = employeeId ? getEmployeeDto : getCreateEmployeeDto;
  const employee = useAppSelector(selectEmployee);

  const autocompleteValue =
    SELECT_LANGUAGE_OPTIONS.find(
      option => option.value === employee?.language
    ) || SELECT_LANGUAGE_OPTIONS[0];

  const handleChange = (
    __: React.SyntheticEvent,
    option: { label: string; value: string }
  ) => {
    const action = employeeId
      ? handleLanguageChange
      : handleCreateEmployeeLanguageSelect;

    dispatch(action(option.value));
  };

  return (
    <RowStart>
      <StyledBox>
        <Autocomplete
          disableClearable
          value={autocompleteValue}
          onChange={handleChange}
          className='select-language-drop-down'
          options={SELECT_LANGUAGE_OPTIONS}
          renderInput={params => (
            <TextField {...params} label={t('general.select_language')} />
          )}
        />
        <CustomInput
          sx={{ width: 300 }}
          disabled={!!employeeId}
          label={t('general.email_address_business')}
          value={employee?.email}
          required
          onInput={val => dispatch(handleCreateEmployeeEmailChange(val))}
        />
      </StyledBox>
    </RowStart>
  );
}
