import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../../../api/api';
import handleError from '../../utils/handleError';
import { User } from './../slices/userSettingsSlice';

export const UserSettings = {
  GET_USER: 'User/GetUser',
};

export const getUser = createAsyncThunk(
  UserSettings.GET_USER,
  async (__, thunkApi) =>
    await api.get<User>(UserSettings.GET_USER).catch(handleError(thunkApi))
);
