import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

import { IError } from './../../types/shared/error';

import { LocaleId } from '../../../constants/general';

import { getUser } from '../services/userSettingsService';

export interface LocaleOption {
  id: string;
  label: string;
}

export interface User {
  firstName: string;
  lastName: string;
}

export interface UserSettingsState {
  localeOptions: LocaleOption[];
  currentLocaleId: string;
  user: User;
  isLoading: boolean;
  error: IError;
}

export const initialState: UserSettingsState = {
  localeOptions: [
    { id: LocaleId.NL, label: 'Dutch' },
    { id: LocaleId.EN, label: 'English' },
  ],
  currentLocaleId: LocaleId.EN,
  user: {
    firstName: '',
    lastName: '',
  },
  isLoading: false,
  error: {},
};

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setCurrentLocaleId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      currentLocaleId: payload,
    }),
    setLocaleOptions: (state, { payload }: PayloadAction<LocaleOption[]>) => ({
      ...state,
      localeOptions: payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(
      getUser.fulfilled,
      (state, { payload }: PayloadAction<User>) => ({
        ...state,
        isLoading: false,
        user: payload,
      })
    );
    builder.addCase(getUser.pending, state => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.error = (payload as IError) || initialState.error;
      state.isLoading = false;
    });
  },
});

export const selectUserFullName = ({ userSettings }: RootState) =>
  {
    const { user } = userSettings;

    if (!user.firstName && !user.lastName) {
      return null;
    }

    return `${user.firstName} ${user.lastName}`;
  };

export const selectUserIsLoading = ({ userSettings }: RootState) =>
  userSettings.isLoading;

export const selectUserSettingsError = ({ userSettings }: RootState) =>
  userSettings.error;

export const selectLocales = (state: RootState) =>
  state.userSettings.localeOptions;

export const selectCurrentLocaleId = (state: RootState) =>
  state.userSettings.currentLocaleId;

export const selectCurrentLanguage = createSelector(
  [selectLocales, selectCurrentLocaleId],
  (locales, curLocaleId) =>
    locales.find(({ id }: LocaleOption) => id === curLocaleId)?.label
);

export const { setLocaleOptions, setCurrentLocaleId } =
  userSettingsSlice.actions;

export default userSettingsSlice.reducer;
