import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCompanyList } from '../services/company_service'
import { RootState } from '../../../app/store'
import { ICompanyTableState } from '../../types/company/company_table_state'
import { StatusEnum } from '../../types/shared/status'

export const initialState: ICompanyTableState = {
  status: StatusEnum.INITIAL,
  data: [],
  searchValue: '',
  error: { message: '' },
}

export const companyTableSlice = createSlice({
  name: 'companyTable',
  initialState,
  reducers: {
    handleSearchValueChange(state: ICompanyTableState, action: PayloadAction<string>) {
      state.searchValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyList.fulfilled, (state: ICompanyTableState, { payload }) => {
      state.data = payload
      state.status = StatusEnum.LOADED
      state.error = initialState.error
    })
    builder.addCase(getCompanyList.pending, (state: ICompanyTableState) => {
      state.status = StatusEnum.PENDING
      state.error = initialState.error
    })
    builder.addCase(getCompanyList.rejected, (state, { payload }) => {
      state.status = StatusEnum.ERROR
      state.error = payload || initialState.error
    })
  },
})

export const getCompanies = (state: RootState) => state.companyTable.data
export const getCompanyListStatus = (state: RootState) => state.companyTable.status
export const getCompanySearchValue = (state: RootState) => state.companyTable.searchValue
export const getCompanyListError = (state: RootState) => state.companyTable.error

export const {
  handleSearchValueChange,
} = companyTableSlice.actions
export default companyTableSlice.reducer
