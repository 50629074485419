import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SeverityLevel } from '@sentry/types/types/severity';

interface LogEventProps {
  componentName: string;
  error: unknown;
  level?: SeverityLevel;
}

const DSN = process.env.REACT_APP_SENTRY_DSN || '';
const ENABLED = process.env.REACT_APP_SENTRY_ENABLED;
const RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
const ATTACH_STACKTRACE = process.env.REACT_APP_SENTRY_ATTACH_STACKTRACE;
const MAX_VALUE_LENGTH = process.env.REACT_APP_SENTRY_MAX_VALUE_LENGTH;
const MAX_BREADCRUMBS = process.env.REACT_APP_SENTRY_MAX_BREADCRUMBS;

export default class SentryHelpers {
  public static init() {
    Sentry.init({
      dsn: DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      enabled: ENABLED ? ENABLED.toString() === 'true' : undefined,
      release: RELEASE ? RELEASE : undefined,
      attachStacktrace: ATTACH_STACKTRACE
        ? ATTACH_STACKTRACE.toString() === 'true'
        : undefined,
      maxBreadcrumbs: MAX_BREADCRUMBS ? Number(MAX_BREADCRUMBS) : undefined,
      maxValueLength: MAX_VALUE_LENGTH ? Number(MAX_VALUE_LENGTH) : undefined,
    });
  }

  public static logEvent({
    componentName,
    error,
    level = 'error',
  }: LogEventProps) {
    Sentry.configureScope(scope => scope.setTransactionName(componentName));
    Sentry.captureException(error, {
      fingerprint: [componentName],
      level,
    });
  }

  public static withSentryErrorBoundary = Sentry.withErrorBoundary;
}
