import styled from 'styled-components'
import { styled as muiStyled } from '@mui/material/styles';

export type RowVariant = 'start' | 'end' | 'flex-start' | 'flex-end' | 'center' | 'left' | 'right' | 'normal' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';

interface RowProps {
  variant: RowVariant,
}

export const Row = muiStyled('div')(({ variant }: RowProps) => ({
  display: 'flex',
  justifyContent: variant,
}));

// TODO: use 'Row' & styled from @mui
export const RowStart = styled.div`
  display: flex;
  justify-content: start;
`

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
`