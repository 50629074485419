import { Edit } from "@mui/icons-material";
import { CustomIconButton, CustomIconButtonProps } from "../icon_buttons";
import { styled } from '@mui/material';
import { baseWhite, borderGray } from '../../../style/colors';

const StyledEditButton = styled(CustomIconButton)({
  width: '24px',
  height: '24px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  gap: '10px',
  background: baseWhite,
  border: `1px solid ${borderGray}`,
  borderRadius: '4px',
  order: 0,
  flexGrow: 0,

  svg: {
    fontSize: '16px',
  }
});

const EditButton = ({ onClick, dataTestId }: CustomIconButtonProps) => (
  <StyledEditButton
    dataTestId={dataTestId}
    className='edit-button'
    onClick={onClick}
    child={<Edit />}
  />
);

export default EditButton;
