import { ChevronLeft } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TestId } from '../../constants/testIds'
import CustomButton from '../shared/custom_button'
import { RowStart } from '../shared/row'

export interface NavRowProps {
  handleOnClick(isSaveAndBackButtonClicked: boolean): void;
  isLoading: boolean;
  goBackRoute?: string;
}

export default function NavRow({
  handleOnClick,
  isLoading,
  goBackRoute,
}: NavRowProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='general_nav_row'>
      <RowStart>
        <CustomButton
          textCenter
          dataTestId={TestId.BACK_BUTTON}
          label={t('utils.back')}
          variant='text'
          wrapperVariant='center'
          startIcon={<ChevronLeft />}
          onClick={() => goBackRoute ? navigate(goBackRoute) : navigate(-1)}
          className='action_button'
        />
        <CustomButton
          dataTestId={TestId.SAVE_BUTTON}
          label={t('utils.save')}
          variant='outlined'
          loading={isLoading}
          className='action_button'
          type='submit'
          onClick={() => handleOnClick(false)}
        />
        <CustomButton
          dataTestId={TestId.SAVE_AND_CLOSE_BUTTON}
          label={t('utils.saveAndClose')}
          onClick={() => handleOnClick(true)}
          type='submit'
          loading={isLoading}
        />
      </RowStart>
    </div>
  )
}
