import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TabPanel from '../shared/tab_panel'
import CreateEmployeeForm from './create_employee_form'
import UpdateEmployeeForm from './update_employee_form'
import EmployeeActionsRow from './components/employeeActionsRow';
import EmployeeTabContent from './components/employeeTabContent';

export interface EmployeeTabNavProps {
  employeeId: string
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function EmplyeeTabNav({ employeeId }: EmployeeTabNavProps) {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (__: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('navigation.general')} {...tabProps(0)} />
          <Tab label={t('navigation.user')} {...tabProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {employeeId ? <UpdateEmployeeForm /> : <CreateEmployeeForm />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmployeeTabContent employeeId={employeeId} />
        {employeeId && <EmployeeActionsRow />}
      </TabPanel>
    </Box>
  );
}
