import { Box, styled } from '@mui/material';

import { APP_SIDEBAR_WIDTH } from '../../constants/general';

import { backgroundBlack, baseWhite } from '../../style/colors';

const SidebarWrapper = styled(Box)({
  width: APP_SIDEBAR_WIDTH,
  flexShrink: 0,

  '.sidebar-drawer': {
    display: 'block',

    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: APP_SIDEBAR_WIDTH,
      backgroundColor: backgroundBlack,
    },
  },

  '.sidebar-logo': {
    color: baseWhite,
    marginTop: '22px',

    '.MuiTypography-root': {
      marginLeft: '10px',
    },
  },

  '.sidebar-item': {
    justifyContent: 'space-between',
    color: baseWhite,

    '.sidebar-item-icon': {
      minWidth: 0,
      color: baseWhite,
    },
  },
});

export default SidebarWrapper;
