import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { useAppTranslation } from '../../hooks/useAppTranslation';

interface WarningDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const dialogStyles = {
  '.MuiDialogTitle-root': {
    padding: '24px',
  },
  '.MuiDialogActions-root': {
    padding: '0 24px 24px',
  },
};

export default function RemoveEmployeeWarning({
  isOpen,
  handleClose,
  handleConfirm,
}: WarningDialogProps) {
  const { t } = useAppTranslation('general');

  return (
    <Dialog
      sx={dialogStyles}
      className='remove-employee-warning'
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {t('remove_user_warning_text')}
      </DialogTitle>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>{t('cancel')}</Button>
        <Button variant='contained' onClick={handleConfirm} autoFocus>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
