import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  getEmployeeDto,
  getEmployeeDtoError,
  getEmployeeDtoStatus,
  handleEmailChange,
  handleFirstNameChange,
  handleLastNameChange,
  handleNamePrefixChange,
  updateEmployeeDtoError,
} from '../../features/employee/slices/getAndUpdateEmployee'
import { StatusEnum } from '../../features/types/shared/status'
import CustomInput from '../shared/custom_input'
import { ErrorContainer } from '../shared/error_container';
import Loader from '../shared/loader'
import { RowStart } from '../shared/row'
import useErrorMessage from '../../hooks/useErrorMessage';
import { getResetPasswordError } from '../../features/employee/slices/userTabSlice';

export interface UpdateEmployeeProps {
  employeeId: string
}

export default function UpdateEmployeeForm() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const employeeDto = useAppSelector(getEmployeeDto)
  const updateEmployeeError = useAppSelector(updateEmployeeDtoError)
  const getEmployeeError = useAppSelector(getEmployeeDtoError)
  const resetPasswordError = useAppSelector(getResetPasswordError)
  const getEmployeeStatus = useAppSelector(getEmployeeDtoStatus)
  const updateEmployeeErrorMessage = useErrorMessage(updateEmployeeError);
  const getEmployeeErrorMessage = useErrorMessage(getEmployeeError);
  const resetPasswordErrorMessage = useErrorMessage(resetPasswordError);

  if (getEmployeeStatus === StatusEnum.PENDING || getEmployeeStatus === StatusEnum.INITIAL) {
    return <Loader />
  } else if (getEmployeeStatus === StatusEnum.LOADED) {
    return (
      <div style={{ marginTop: '24px' }}>
        <RowStart>
          <CustomInput
            value={employeeDto?.firstName || ''}
            className='generalCard_input'
            label={t('general.first_name')}
            required
            onInput={(val) => dispatch(handleFirstNameChange(val))}
          />
          <CustomInput
            value={employeeDto?.namePrefix || ''}
            className='generalCard_input'
            label={t('general.name_prefix')}
            onInput={(val) => dispatch(handleNamePrefixChange(val))}
          />
          <CustomInput
            value={employeeDto?.lastName || ''}
            className='generalCard_input'
            label={t('general.last_name')}
            required
            onInput={(val) => dispatch(handleLastNameChange(val))}
          />
          <CustomInput
            sx={{ width: 300 }}
            value={employeeDto?.email || ''}
            label={t('general.email_address_business')}
            disabled
            onInput={(val) => dispatch(handleEmailChange(val))}
          />
        </RowStart>
        <ErrorContainer>{updateEmployeeErrorMessage}</ErrorContainer>
        <ErrorContainer>{resetPasswordErrorMessage}</ErrorContainer>
      </div>
    )
  } else if (getEmployeeStatus === StatusEnum.ERROR) {
    return <ErrorContainer>{getEmployeeErrorMessage}</ErrorContainer>
  } else {
    return <ErrorContainer>{t('management_table.error')}</ErrorContainer>
  }
}
