export const apiUrl = process.env.REACT_APP_API_URL;

const DEFAULT_ERROR = 'Er is iets fout gegaan';

type ResponseTextType = {
  message?: string;
  Message?: string;
  errorCode?: string;
  ErrorCode?: string;
  status?: string;
  Status?: string;
};

const parseResponseText = (text: ResponseTextType) => ({
  message: text.message || text.Message || DEFAULT_ERROR,
  errorCode: text.errorCode || text.ErrorCode,
  status: text.status || text.Status,
});

async function http<T>(path: string, config: RequestInit): Promise<T> {
  const request = new Request(apiUrl + path, config);
  try {
    const response = await fetch(request);
    if (!response.ok) {
      if (response.statusText) {
        throw new Error(response.statusText);
      }

      const string = await response.text();
      const responseJsonError = string ? JSON.parse(string) : DEFAULT_ERROR;

      // FIX: avoid "[object Object]"
      if (typeof responseJsonError === 'string') {
        throw new Error(responseJsonError);
      }

      throw parseResponseText(responseJsonError);
    }
    // FIX: unexpected end of json input response.json
    const string = await response.text();
    return JSON.parse(string || '{}');
  } catch (err) {
    console.error('http module error:', err);
    throw err;
  }
}

export function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
    ...config,
  };

  return http<U>(path, init);
}

export function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
    ...config,
  };

  return http<U>(path, init);
}

export function get<U>(path: string, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
    ...config,
  };

  return http<U>(path, init);
}

export function remove<U>(path: string, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    method: 'DELETE',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    mode: 'cors',
    ...config,
  };

  return http<U>(path, init);
}
