import { InputAdornment, TextField, SxProps } from '@mui/material'
import { baseWhite } from '../../style/colors'
import SearchIcon from '@mui/icons-material/Search'
import { ReactNode } from 'react'
export interface InputProps {
  value: string | undefined
  onInput?: (e: string) => void
  placeholder?: string
  label?: string
  dataTestId?: string
  icon?: InputIconCode
  required?: boolean
  error?: boolean
  className?: string
  helperText?: string
  disabled?: boolean
  sx?: SxProps
}

export type InputIconCode = 'search'

function getInputIcon(iconCode: InputIconCode): ReactNode {
  if (iconCode === 'search') {
    return <SearchIcon />
  }
}

export default function CustomInput({
  value,
  placeholder,
  label,
  dataTestId,
  onInput = () => {/**/},
  icon,
  required = false,
  error = false,
  className,
  helperText,
  disabled = false,
  sx,
}: InputProps) {
  return (
    <TextField
      data-testid={dataTestId}
      value={value}
      placeholder={placeholder}
      id='outlined-basic'
      variant='outlined'
      size='small'
      label={label}
      required={required}
      type='text'
      helperText={helperText}
      error={error}
      disabled={disabled}
      sx={{
        ...(sx || {}),
        backgroundColor: baseWhite,
      }}
      className={className}
      InputProps={
        !icon
          ? {}
          : {
              startAdornment: <InputAdornment position='start'>{getInputIcon(icon)}</InputAdornment>,
            }
      }
      onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInput(event.target.value)}
    />
  )
}
