import { SnackbarContent, SnackbarKey } from 'notistack';
import { forwardRef } from 'react';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { SnackbarVariant } from '../../constants/general';

import { baseWhite, snackBarError, snackBarSuccess } from '../../style/colors';

interface SnackbarProps {
  id: SnackbarKey;
  variant: string;
  message: string;
  handleClose: (id?: SnackbarKey) => void;
}

const StyledCard = styled(Card)(({ type }: { type: string }) => ({
  backgroundColor:
    type === SnackbarVariant.SUCCESS ? snackBarSuccess : snackBarError,
  color: baseWhite,
  '.head-icon': {
    height: '35.83px',
    width: '35.83px',
  },
  '.MuiTypography-root': {
    marginLeft: 22,
    marginRight: 22,
  },
}));

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  ({ id, variant, message, handleClose }, ref) => (
    <SnackbarContent ref={ref}>
      <StyledCard type={variant}>
        <CardActions>
          {variant === SnackbarVariant.SUCCESS ? (
            <CheckOutlinedIcon className='head-icon' />
          ) : (
            <ErrorOutlinedIcon className='head-icon' />
          )}
          <Typography>{message}</Typography>
          <div>
            <IconButton size='small' onClick={() => handleClose(id)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
        </CardActions>
      </StyledCard>
    </SnackbarContent>
  )
);

Snackbar.displayName = 'Snackbar';

export default Snackbar;
