import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboardArrowDown, Language } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StyledWrapper from './styledWrapper';

import {
  selectCurrentLanguage,
  selectCurrentLocaleId,
  selectLocales,
  selectUserFullName,
  setCurrentLocaleId,
} from '../../features/user_settings/slices/userSettingsSlice';

import { TestId } from '../../constants/testIds';

import { routesDefinitions } from '../../routes';
import Breadcrumbs from './breadcrumbs';
import DropdownMenu from './dropdownMenu';

export const languageMenuId = 'language-menu';
export const userMenuId = 'user-menu';

export default function Header() {
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const curLanguage = useAppSelector(selectCurrentLanguage);
  const curLocale = useAppSelector(selectCurrentLocaleId);
  const userFullName =
    useAppSelector(selectUserFullName) || t('general.unknownUserFullName');
  const locales = useAppSelector(selectLocales);

  const dropdownMenuId = anchorEl?.dataset?.menuid;

  const isLanguageMenuOpen = dropdownMenuId === languageMenuId;
  const isUserMenuOpen = dropdownMenuId === userMenuId;

  const options = useMemo(
    () => ({
      [languageMenuId]: locales,
      [userMenuId]: [{ id: 'userLogout', label: 'Log Out' }],
    }),
    [locales]
  );

  const handleMenuOpen = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenuItemClick = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      const localeId = currentTarget.dataset?.id || '';

      dispatch(setCurrentLocaleId(localeId));
      i18n.changeLanguage(localeId);

      setAnchorEl(null);
    },
    [dispatch, i18n]
  );

  const handleUserMenuItemClick = () => {
    setAnchorEl(null);
    window.location.replace(routesDefinitions.logout);
  };

  return (
    <StyledWrapper>
      <Breadcrumbs />
      <Box className='user-settings-wrapper'>
        <Button
          className='language-switcher'
          data-testid={TestId.HEADER_LANGUAGE_SWITCH_BUTTON}
          disableElevation
          disableFocusRipple
          disableRipple
          data-menuid={languageMenuId}
          size='medium'
          variant='text'
          onClick={handleMenuOpen}
          startIcon={<Language />}
          endIcon={<KeyboardArrowDown />}
        >
          {curLanguage}
        </Button>
        <Button
          className='user-switcher'
          disableElevation
          disableFocusRipple
          disableRipple
          data-menuid={userMenuId}
          size='medium'
          variant='text'
          onClick={handleMenuOpen}
          endIcon={<KeyboardArrowDown />}
        >
          {userFullName}
        </Button>

        {isLanguageMenuOpen && (
          <DropdownMenu
            isMenuOpen={isLanguageMenuOpen}
            anchorEl={anchorEl}
            menuId={languageMenuId}
            options={options[dropdownMenuId]}
            selectedOption={curLocale}
            onClose={handleMenuClose}
            onClick={handleLanguageMenuItemClick}
          />
        )}

        {isUserMenuOpen && (
          <DropdownMenu
            isMenuOpen={isUserMenuOpen}
            anchorEl={anchorEl}
            menuId={userMenuId}
            options={options[dropdownMenuId]}
            selectedOption={''}
            onClose={handleMenuClose}
            onClick={handleUserMenuItemClick}
          />
        )}
      </Box>
    </StyledWrapper>
  );
}
