import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getEmployee, updateEmployee } from '../../features/employee/services/employee_service'
import {
  getEmployeeDto,
  updateEmployeeDtoStatus,
} from '../../features/employee/slices/getAndUpdateEmployee';
import { getEnableEmployeeStatus, getResetPasswordStatus } from '../../features/employee/slices/userTabSlice'
import { StatusEnum } from '../../features/types/shared/status'
import NavRow from '../company/nav_row'
import { MainContainer } from '../shared/main_container'
import EmplyeeTabNav from './emplyee_tab_nav'
import { useNotification } from '../../hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { selectCompanyId } from '../../features/company_table/slices/getAndUpdateCompanySlice';
import { getCompany } from '../../features/company_table/services/company_service';

export default function UpdateEmployee() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const updateEmployeeStatus = useAppSelector(updateEmployeeDtoStatus)
  const resetPasswordStatus = useAppSelector(getResetPasswordStatus)
  const enableEmployeeStatus = useAppSelector(getEnableEmployeeStatus)
  const employeeDto = useAppSelector(getEmployeeDto);
  const storedCompanyId = useAppSelector(selectCompanyId);

  const { companyId, employeeId } = useParams()
  const [isSaveAndBackButtonClicked, setIsSaveAndBackButtonClicked] = useState<boolean>(false);
  const { notifySuccess, notifyError } = useNotification();
  const { t } = useTranslation();
  
  const isEmailSendActionStatusLoaded =
    resetPasswordStatus === StatusEnum.LOADED;

  const isEnableEmployeeStatusLoaded =
    enableEmployeeStatus === StatusEnum.LOADED;

  const handleUpdateEmployee = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const employeeId = await dispatch(
        updateEmployee({
          id: employeeDto?.id || '',
          firstName: employeeDto?.firstName,
          lastName: employeeDto?.lastName,
          namePrefix: employeeDto?.namePrefix,
          language: employeeDto?.language,
        })
      ).unwrap();

      if (employeeId) {
        notifySuccess(t('notifications.updated_successfully'));
        isSaveAndBackButtonClicked && navigate(`../company/${companyId}`);
      }
    } catch (err: unknown) {
      notifyError(t('error.common_error'));
      console.error(err);
    }
  }, [companyId, dispatch, employeeDto, isSaveAndBackButtonClicked, navigate, notifyError, notifySuccess, t]);

  useEffect(() => {
    employeeId && dispatch(getEmployee(employeeId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, isEnableEmployeeStatusLoaded, isEmailSendActionStatusLoaded]);

  useEffect(() => {
    if (!storedCompanyId && companyId) {
      dispatch(getCompany(companyId));
    }
  }, [companyId, dispatch, storedCompanyId])

  return (
    <MainContainer>
      <form onSubmit={handleUpdateEmployee}>
        <EmplyeeTabNav employeeId={employeeId ?? ''} />
        <NavRow
          isLoading={updateEmployeeStatus === StatusEnum.PENDING}
          handleOnClick={setIsSaveAndBackButtonClicked}
          goBackRoute={`../company/${companyId}`}
        />
      </form>
    </MainContainer>
  )
}
