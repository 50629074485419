import { useState } from 'react'
import CustomButton from '../shared/custom_button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { routesDefinitions } from '../../routes'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getCompanySearchValue, handleSearchValueChange } from '../../features/company_table/slices/companyTableSlice'
import { Row } from '../shared/row'
import { StyledSearchInput, StyledSearchButton } from './search';
import { styled } from '@mui/material/styles';
import { TestId } from '../../constants/testIds'

const StyledRowWrapper = styled(Row)({
  flexWrap: 'wrap',
  alignItems: 'center',
  padding: '14px 0 23px 0',

  '.actionRow_buttons': {
    display: 'flex',
    
    'button:first-of-type': {
      marginRight: '14px',
    }
  },
});

const StyledRow = styled(Row)({
  maxWidth: '539px',
  width: '100%',
});

export default function ActionRow() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const companyListSearchValue = useAppSelector(getCompanySearchValue);

  const [searchValue, setSearchValue] = useState(companyListSearchValue || '');

  return (
  <StyledRowWrapper variant='space-between'>
      <div className='actionRow_buttons'>
        <CustomButton
          dataTestId={TestId.ADD_CONCEPT_COMPANY_BUTTON}
          label={t('nav_buttons.add_concept')}
          onClick={() => {
            navigate(routesDefinitions.createConcept)
          }}
        />
      </div>
      <StyledRow variant="end">
        <StyledSearchInput
          value={searchValue}
          placeholder={t('management_table.search_by_email')}
          onInput={setSearchValue}
          className='generalCard_input'
        />
        <StyledSearchButton
          label={t('utils.search')}
          onClick={() => dispatch(handleSearchValueChange(searchValue))}
        />
      </StyledRow>
    </StyledRowWrapper>
  )
}
