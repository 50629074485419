import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
} from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { getCompanyList } from '../../../features/company_table/services/company_service';
import {
  getCompanies,
  getCompanyListError,
  getCompanyListStatus,
  getCompanySearchValue,
} from '../../../features/company_table/slices/companyTableSlice';
import { StatusEnum } from '../../../features/types/shared/status';

import EditButton from '../../shared/editButton';
import { ErrorContainer } from '../../shared/error_container';
import Loader from '../../shared/loader';
import StyledTableContainer from '../../shared/styledTableContainer';

import { ROWS_PER_PAGE_OPTIONS } from '../../../constants/general';
import { TestId } from '../../../constants/testIds';

import DateHelpers from '../../../helpers/DateHelpers';
import getDataGridLocale from '../../../helpers/getDataGridLocale';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { routesDefinitions } from '../../../routes';

const initialState = {
  sorting: {
    sortModel: [{ field: 'lastModified', sort: 'desc' as GridSortDirection }],
  },
};

export default function UserManagementTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'management_table',
  });

  const companyList = useAppSelector(getCompanies);
  const companyListStatus = useAppSelector(getCompanyListStatus);
  const companyListSearchValue = useAppSelector(getCompanySearchValue);
  const companyListError = useAppSelector(getCompanyListError);
  const errorMessage = useErrorMessage(companyListError);

  useEffect(() => {
    dispatch(getCompanyList(companyListSearchValue));
  }, [companyListSearchValue, dispatch]);

  const companyTableColumns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('column_headers.name'),
        flex: 1,
      },
      {
        field: 'created',
        headerName: t('column_headers.createdAt'),
        flex: 1,
        type: 'date',
        renderCell: (params: GridRenderCellParams) => (
          <span>
            {DateHelpers.dateTypeToHumanReadableConverter(params.value)}
          </span>
        ),
      },
      {
        field: 'lastModified',
        headerName: t('column_headers.updatedAt'),
        flex: 1,
        type: 'date',
        renderCell: (params: GridRenderCellParams<Date>) => (
          <span>
            {DateHelpers.dateTypeToHumanReadableConverter(params.value)}
          </span>
        ),
      },
      {
        field: 'id',
        headerName: t('column_headers.edit'),
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => {
          const onClick = () => {
            navigate(routesDefinitions.editDashboard + '/' + params.id);
          };

          return (
            <EditButton dataTestId={TestId.EDIT_BUTTON} onClick={onClick} />
          );
        },
      },
    ],
    [navigate, t]
  );

  const isLoading =
    companyListStatus === StatusEnum.PENDING ||
    companyListStatus === StatusEnum.INITIAL;

  if (isLoading) {
    return <Loader />;
  }

  if (companyListStatus === StatusEnum.LOADED) {
    return (
      <StyledTableContainer>
        <DataGrid
          localeText={getDataGridLocale(i18n.language)}
          rows={companyList}
          columns={companyTableColumns}
          pagination
          initialState={initialState}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          disableColumnSelector
        />
      </StyledTableContainer>
    );
  }

  if (companyListStatus === StatusEnum.ERROR) {
    return <ErrorContainer>{errorMessage}</ErrorContainer>;
  }

  return <ErrorContainer>{t('error')}</ErrorContainer>;
}
