import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { TestId } from '../../constants/testIds'
import {
  getCreateCompanyDto,
  getCreateCompanyError,
  handleClearCreateCompanyDto,
  handleCompanyIdChange,
  handleCompanyNameChange,
} from '../../features/company_table/slices/createCompanySlice'
import CustomInput from '../shared/custom_input'
import { ErrorContainer } from '../shared/error_container'
import { RowStart } from '../shared/row'
import useErrorMessage from '../../hooks/useErrorMessage';

export default function CreateCompanyDetails() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const createCompanyDto = useAppSelector(getCreateCompanyDto)
  const createCompanyError = useAppSelector(getCreateCompanyError)
  const errorMessage = useErrorMessage(createCompanyError);

  useEffect(() => {
    dispatch(handleClearCreateCompanyDto())
  }, [dispatch])

  return (
    <div>
      <RowStart>
        <CustomInput
          dataTestId={TestId.ADD_COMPANY_NAME_INPUT}
          value={createCompanyDto.name}
          onInput={(val) => dispatch(handleCompanyNameChange(val))}
          label={t('general.company_name')}
          placeholder={t('general.company_name')}
          required
          className='generalCard_input'
          />
        <CustomInput
          dataTestId={TestId.ADD_COMPANY_ID_INPUT}
          value={createCompanyDto.externalId}
          onInput={(val) => dispatch(handleCompanyIdChange(val))}
          label={t('general.id')}
          placeholder={t('general.id')}
          sx={{ width: 300 }}
          required
        />
      </RowStart>
      <ErrorContainer>{errorMessage}</ErrorContainer>
    </div>
  )
}
