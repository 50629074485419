import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Build, Home } from '@mui/icons-material';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import SidebarWrapper from './sidebarWrapper';

import { TestId } from '../../constants/testIds';

import { routesDefinitions } from '../../routes';

export default function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToDashboard = useCallback(() => {
    navigate(routesDefinitions.dashboard);
  }, [navigate]);

  return (
    <SidebarWrapper
      data-testid={TestId.SIDEBAR}
      component='nav'
      aria-label='dashboard'
    >
      <Drawer variant='permanent' className='sidebar-drawer'>
        <Toolbar className='sidebar-logo'>
          <Build />
          <Typography>CarSys<br />Support</Typography>
        </Toolbar>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              data-testid={TestId.SIDEBAR_DASHBOARD_LINK}
              className='sidebar-item'
              onClick={handleNavigateToDashboard}
            >
              <ListItemText primary={t('sidebar.dashboard')} />
              <ListItemIcon className='sidebar-item-icon'>
                <Home />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </SidebarWrapper>
  );
}
