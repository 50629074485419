import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import ICreateCompanyState from '../../types/company/create_company_state'
import { StatusEnum } from '../../types/shared/status'
import { createCompany, getCompanyList } from '../services/company_service'
import { IError } from '../../types/shared/error';

export const emptyCreateCompanyDto = {
  externalId: '',
  name: '',
}

export const initialState: ICreateCompanyState = {
  status: StatusEnum.INITIAL,
  companyId: '',
  createCompanyDto: emptyCreateCompanyDto,
  error: { message: '' },
}

export const createCompanySlice = createSlice({
  name: 'createCompany',
  initialState,
  reducers: {
    handleCompanyNameChange(state: ICreateCompanyState, action: PayloadAction<string>) {
      state.createCompanyDto.name = action.payload
    },
    handleCompanyIdChange(state: ICreateCompanyState, action: PayloadAction<string>) {
      state.createCompanyDto.externalId = action.payload
    },
    handleFormValidationError(state: ICreateCompanyState, action: PayloadAction<IError>) {
      state.error = action.payload
    },
    handleClearCreateCompanyDto(state: ICreateCompanyState) {
      state.createCompanyDto = emptyCreateCompanyDto
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyList.fulfilled, (state: ICreateCompanyState) => {
      state.error = initialState.error
    })
    builder.addCase(createCompany.fulfilled, (state: ICreateCompanyState, { payload }) => {
      state.companyId = payload
      state.status = StatusEnum.LOADED
      state.error = initialState.error
    })
    builder.addCase(createCompany.pending, (state: ICreateCompanyState) => {
      state.status = StatusEnum.PENDING
      state.error = initialState.error
    })
    builder.addCase(createCompany.rejected, (state, { payload }) => {
      state.status = StatusEnum.ERROR
      state.error = payload || initialState.error
    })
  },
})

export const getCreateCompanyId = (state: RootState) => state.createCompany.companyId
export const getCreateCompanyDto = (state: RootState) => state.createCompany.createCompanyDto
export const getCreateCompanyStatus = (state: RootState) => state.createCompany.status
export const getCreateCompanyError = (state: RootState) => state.createCompany.error

export const {
  handleCompanyNameChange,
  handleCompanyIdChange,
  handleFormValidationError,
  handleClearCreateCompanyDto,
} = createCompanySlice.actions
export default createCompanySlice.reducer
