import { combineReducers } from '@reduxjs/toolkit';

import { companyTableSlice } from '../features/company_table/slices/companyTableSlice';
import { createCompanySlice } from '../features/company_table/slices/createCompanySlice';
import { getAndUpdateCompanySlice } from '../features/company_table/slices/getAndUpdateCompanySlice';
import { createEmployeeSlice } from '../features/employee/slices/createEmployeeSlice';
import { getAndUpdateEmployeeSlice } from '../features/employee/slices/getAndUpdateEmployee';
import { employeeListFromCompanySlice } from '../features/employee/slices/getEmployeeFromCompanySlice';
import { userTabSlice } from '../features/employee/slices/userTabSlice';
import { userSettingsSlice } from '../features/user_settings/slices/userSettingsSlice';

const rootReducer = combineReducers({
  companyTable: companyTableSlice.reducer,
  createCompany: createCompanySlice.reducer,
  editAndUpdateCompany: getAndUpdateCompanySlice.reducer,
  createEmployee: createEmployeeSlice.reducer,
  employeeListFromCompany: employeeListFromCompanySlice.reducer,
  getAndUpdateEmployee: getAndUpdateEmployeeSlice.reducer,
  userTab: userTabSlice.reducer,
  userSettings: userSettingsSlice.reducer,
});

export default rootReducer;
