import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box } from '@mui/material'
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import TabPanel from '../shared/tab_panel'
import EditCompanyDetails from './edit_company_details'
import CreateCompanyDetails from './create_company_details'

export interface TabNavProps {
  companyId: string
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function TabNav({companyId} :TabNavProps ) {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = useCallback(
    (__: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  return (
    <Box>
      <Box sx={{ marginBottom: '24px' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('navigation.general')} {...tabProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {companyId ? <EditCompanyDetails /> : <CreateCompanyDetails/>}
      </TabPanel>
    </Box>
  )
}
