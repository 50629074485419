import { styled } from '@mui/material';

import { APP_HEADER_HEIGHT } from '../../constants/general';

import { mainRed, textBlack } from '../../style/colors';

const StyledWrapper = styled('header')({
  height: APP_HEADER_HEIGHT,
  margin: '0 24px 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',

  '.current_path_indicator': {
    color: mainRed,
    textTransform: 'capitalize',
  },

  '.user-settings-wrapper': {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 'auto',
    color: textBlack,

    'button.language-switcher, button.user-switcher': {
      textTransform: 'capitalize',
      color: textBlack,
      fontWeight: '500',
      fontSize: '16px',
    },

    '.MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px',

      '&:first-of-type': {
        margin: '0 25px',
      },
    },
  },
});

export default StyledWrapper;
