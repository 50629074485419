import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { IEmployeeListFromCompanyState } from "../../types/employee/emplyeeListFromCompanyState"
import { StatusEnum } from "../../types/shared/status"
import { getEmployeeListFromCompany } from "../services/employee_service"

const initialState: IEmployeeListFromCompanyState = {
  status: StatusEnum.INITIAL,
  employeeList: [],
  companyId: '',
  error: { message: '' },
}

export const employeeListFromCompanySlice = createSlice({
  name: 'employeeListFromCompany',
  initialState,
  reducers: {
    handleCompanyIdChange(state: IEmployeeListFromCompanyState, action: PayloadAction<string>) {
      state.companyId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeListFromCompany.fulfilled, (state: IEmployeeListFromCompanyState, { payload }) => {
      state.employeeList = payload
      state.status = StatusEnum.LOADED
      state.error.message = ''
    })
    builder.addCase(getEmployeeListFromCompany.pending, (state: IEmployeeListFromCompanyState) => {
      state.status = StatusEnum.PENDING
      state.error.message = ''
    })
    builder.addCase(getEmployeeListFromCompany.rejected, (state: IEmployeeListFromCompanyState, { payload }) => {
      state.status = StatusEnum.ERROR
      state.error = payload || initialState.error
    })
  },
})

export const getEmployeeList = (state: RootState) => state.employeeListFromCompany.employeeList
export const getEmployeeListStatus = (state: RootState) => state.employeeListFromCompany.status
export const getEmployeeListError = ({ employeeListFromCompany }: RootState) => employeeListFromCompany.error

export const {
  handleCompanyIdChange,
} = employeeListFromCompanySlice.actions

export default employeeListFromCompanySlice.reducer
