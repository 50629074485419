import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';

import Snackbar from '../components/shared/snackbar';

import { SnackbarVariant } from '../constants/general';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = (id?: SnackbarKey) => closeSnackbar(id);

  const notifyError = (message: string, options?: OptionsObject | undefined) =>
    enqueueSnackbar(message, {
      content: (id: SnackbarKey) => (
        <Snackbar
          id={id}
          message={message}
          variant={SnackbarVariant.ERROR}
          handleClose={handleClose}
        />
      ),
      ...options,
    });

  const notifySuccess = (
    message: string,
    options?: OptionsObject | undefined
  ) =>
    enqueueSnackbar(message, {
      content: (id: SnackbarKey) => (
        <Snackbar
          id={id}
          message={message}
          variant={SnackbarVariant.SUCCESS}
          handleClose={handleClose}
        />
      ),
      ...options,
    });

  return {
    notifyError,
    notifySuccess,
    closeNotification: closeSnackbar,
  };
};
