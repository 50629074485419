import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { UserTabState } from '../../types/employee/user_tab_state'
import { StatusEnum } from '../../types/shared/status'
import { changeDisableStatus, resetPassword } from '../services/employee_service'

const initialState: UserTabState = {
  resetPasswordStatus: StatusEnum.INITIAL,
  enableEmployeeStatus: StatusEnum.INITIAL,
  isLoading: false,
  error: { message: '' },
}

// TODO: review this slice - seems like this is general settings
// but somehow it appears to be inside "employee"
export const userTabSlice = createSlice({
  name: 'userTab',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPassword.fulfilled, (state: UserTabState) => {
      state.resetPasswordStatus = StatusEnum.LOADED
      state.isLoading = false
      state.error.message = ''
    })
    builder.addCase(resetPassword.pending, (state: UserTabState) => {
      state.resetPasswordStatus = StatusEnum.PENDING
      state.isLoading = true
      state.error.message = ''
    })
    builder.addCase(resetPassword.rejected, (state: UserTabState, { payload }) => {
      state.resetPasswordStatus = StatusEnum.LOADED
      state.isLoading = false
      state.error = payload || initialState.error
    })
    builder.addCase(changeDisableStatus.fulfilled, (state: UserTabState) => {
      state.enableEmployeeStatus = StatusEnum.LOADED
      state.isLoading = false
      state.error.message = ''
    })
    builder.addCase(changeDisableStatus.pending, (state: UserTabState) => {
      state.enableEmployeeStatus = StatusEnum.PENDING
      state.isLoading = true
      state.error.message = ''
    })
    builder.addCase(changeDisableStatus.rejected, (state: UserTabState, { payload }) => {
      state.enableEmployeeStatus = StatusEnum.ERROR
      state.isLoading = false
      state.error = payload || initialState.error
    })
  },
})

export const getResetPasswordStatus = ({ userTab }: RootState) => userTab.resetPasswordStatus
export const getEnableEmployeeStatus = (state: RootState) => state.userTab.enableEmployeeStatus
export const getIsUserTabActionLoading = (state: RootState) => state.userTab.isLoading
export const getResetPasswordError = ({ userTab }: RootState) => userTab.error

export default userTabSlice.reducer
