import ReactLoading from 'react-loading'
import { mainRed } from '../../style/colors'
import { Row } from './row'

interface LoaderProps {
  width?: string | number;
  height?: string | number;
}

export default function Loader({ width, height }: LoaderProps) {
  return (
    <Row variant="center">
      <ReactLoading type='spin' color={mainRed} width={width} height={height}/>
    </Row>
  )
}
