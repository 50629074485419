import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { createEmployee } from '../../features/employee/services/employee_service'
import {
  getCreateEmployeeDto,
  getCreateEmployeeStatus,
  handleCompanyIdChangeForEmployeeCreate,
} from '../../features/employee/slices/createEmployeeSlice'
import { StatusEnum } from '../../features/types/shared/status'
import NavRow from '../company/nav_row'
import { MainContainer } from '../shared/main_container'
import EmplyeeTabNav from './emplyee_tab_nav'
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../hooks/useNotification';
import { selectCompanyId } from '../../features/company_table/slices/getAndUpdateCompanySlice';
import { getCompany } from '../../features/company_table/services/company_service';

export default function CreateEmployee() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const createEmployeeDto = useAppSelector(getCreateEmployeeDto)
  const createEmployeeStatus = useAppSelector(getCreateEmployeeStatus)
  const storedCompanyId = useAppSelector(selectCompanyId);
  
  const { companyId } = useParams()
  const [isSaveAndBackButtonClicked, setIsSaveAndBackButtonClicked] = useState<boolean>(false);  

  const { notifySuccess, notifyError } = useNotification();
  const { t } = useTranslation();

  const handleCreateEmployee = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const employeeId = await dispatch(
        createEmployee(createEmployeeDto)
      ).unwrap();

      if (employeeId) {
        notifySuccess(t('notifications.created_successfully'));
        notifySuccess(
          t('notifications.send_invite_email') + createEmployeeDto?.email
        );

        const endPath =
          isSaveAndBackButtonClicked ?
            '' : `/editEmployee/${employeeId}`;

        navigate(`../company/${companyId}${endPath}`);
      }
    } catch (err: unknown) {
      notifyError(t('error.common_error'));
      console.error(err);
    }
  }, [companyId, createEmployeeDto, dispatch, isSaveAndBackButtonClicked, navigate, notifyError, notifySuccess, t])

  useEffect(() => {
    companyId && dispatch(handleCompanyIdChangeForEmployeeCreate(companyId));

    if (!storedCompanyId && companyId) {
      dispatch(getCompany(companyId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storedCompanyId])

  return (
    <MainContainer>
      <form onSubmit={handleCreateEmployee}>
        <EmplyeeTabNav employeeId=''/>
        <NavRow
          isLoading={createEmployeeStatus === StatusEnum.PENDING}
          handleOnClick={setIsSaveAndBackButtonClicked}
          goBackRoute={`../company/${companyId}`}
        />
      </form>
    </MainContainer>
  )
}