export const APP_SIDEBAR_WIDTH = 188;

export const APP_HEADER_HEIGHT = 60;

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 100];

export const ALERT_AUTO_HIDE_DURATION = 5000;

export const ALERT_POSITION = { horizontal: 'right', vertical: 'bottom' };

export const SELECT_LANGUAGE_OPTIONS = [
  { label: 'Dutch', value: 'nl_NL' },
  { label: 'English', value: 'en_GB' },
];

export const SnackbarVariant = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const LocaleId = {
  EN: 'en-GB',
  NL: 'nl-NL',
};

export const HttpStatusCode = {
  UNAUTHORIZED: 401,
  CONFLICT: 409,
};

export const ErrorCode = {
  EMAIL_TAKEN: 'email_taken',
}