import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  changeDisableStatus,
  removeEmployee,
  resetPassword,
} from '../../../features/employee/services/employee_service';
import { getEmployeeDto } from '../../../features/employee/slices/getAndUpdateEmployee';
import { getIsUserTabActionLoading } from '../../../features/employee/slices/userTabSlice';

import CustomButton from '../../shared/custom_button';
import { RowStart } from '../../shared/row';

import { TestId } from '../../../constants/testIds';

import { useNotification } from '../../../hooks/useNotification';
import RemoveEmployeeWarning from '../removeEmployeeWarning';

const StyledBox = styled(Box)({
  marginTop: '24px',
});

const EmployeeActionsRow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotification();

  const [openDeleteWarn, setOpenDeleteWarn] = useState(false);

  const employee = useAppSelector(getEmployeeDto);
  const isUserTabActionLoading = useAppSelector(getIsUserTabActionLoading);

  const isEmployeeDisabled = employee?.disabled;

  const handleOpenWarning = () => {
    setOpenDeleteWarn(true);
  };

  const handleCloseWarning = () => {
    setOpenDeleteWarn(false);
  };

  const handleResetPassword = useCallback(
    () =>
      employee?.id &&
      dispatch(resetPassword(employee?.id)).then(() => {
        notifySuccess(
          t('notifications.reset_password_email') + employee?.email
        );
      }),
    [dispatch, employee?.email, employee?.id, notifySuccess, t]
  );

  const handleEnableUser = useCallback(
    () =>
      employee?.id &&
      dispatch(
        changeDisableStatus({
          employeeId: employee?.id,
          isDisabled: false,
        })
      ).then(() => {
        notifySuccess(t('notifications.enable_user'));
      }),
    [dispatch, employee?.id, notifySuccess, t]
  );

  const handleDisableUser = useCallback(
    () =>
      employee?.id &&
      dispatch(
        changeDisableStatus({
          employeeId: employee?.id,
          isDisabled: true,
        })
      ).then(() => {
        notifySuccess(t('notifications.disable_user'));
      }),
    [dispatch, employee?.id, notifySuccess, t]
  );

  const handleRemoveEmployee = useCallback(() => {
    try {
      if (employee?.id) {
        dispatch(removeEmployee(employee.id)).then(() => {
          notifySuccess(t('notifications.remove_user'));
          navigate(`../company/${employee.companyId || ''}`);
        });
      }
    } catch (err) {
      notifyError(t('error.common_error'));
      console.error(err);
    }
  }, [
    dispatch,
    employee?.companyId,
    employee?.id,
    navigate,
    notifyError,
    notifySuccess,
    t,
  ]);

  return (
    <StyledBox>
      <RowStart>
        <CustomButton
          label={t('user_options.reset_password')}
          className='action_button'
          onClick={handleResetPassword}
          variant='outlined'
          loading={isUserTabActionLoading}
        />

        {isEmployeeDisabled && (
          <>
            <CustomButton
              dataTestId={TestId.ENABLE_EMPLOYEE_BUTTON}
              label={t('user_options.enable_user')}
              onClick={handleEnableUser}
              className='action_button'
              loading={isUserTabActionLoading}
            />
            <CustomButton
              dataTestId={TestId.DELETE_EMPLOYEE_BUTTON}
              label={t('user_options.remove_user')}
              onClick={handleOpenWarning}
              className='action_button'
              variant='outlined'
              loading={isUserTabActionLoading}
            />
            <RemoveEmployeeWarning
              isOpen={openDeleteWarn}
              handleConfirm={handleRemoveEmployee}
              handleClose={handleCloseWarning}
            />
          </>
        )}

        {!isEmployeeDisabled && (
          <CustomButton
            dataTestId={TestId.DISABLE_EMPLOYEE_BUTTON}
            label={t('user_options.disable_user')}
            onClick={handleDisableUser}
            className='action_button'
            loading={isUserTabActionLoading}
          />
        )}
      </RowStart>
    </StyledBox>
  );
};

export default EmployeeActionsRow;
