import { createAsyncThunk } from '@reduxjs/toolkit';

import { IError } from '../../types/shared/error';

import * as api from '../../../api/api';
import ICompanyDto from '../../types/company/company_dto';
import { ICreateCompanyDto } from '../../types/company/create_company_dto';
import IUpdateCompanyDto from '../../types/company/update_company_dto';
import handleError from '../../utils/handleError';

export const getCompanyList = createAsyncThunk<
  ICompanyDto[],
  string,
  { rejectValue: IError }
>('Company/GetList', async (searchValue, thunkApi) => {
  const response = await api
    .get<ICompanyDto[]>(`Company/GetList?searchValue=${searchValue}`)
    .catch(handleError(thunkApi));
  return response;
});

export const getCompany = createAsyncThunk<
  ICompanyDto,
  string,
  { rejectValue: IError }
>('Company/GetDetails', async (companyId, thunkApi) => {
  const response = await api
    .get<ICompanyDto>(`Company/GetDetails?id=${companyId}`)
    .catch(handleError(thunkApi));
  return response;
});

export const createCompany = createAsyncThunk<
  string,
  ICreateCompanyDto,
  { rejectValue: IError }
>('Company/Create', async (data: ICreateCompanyDto, thunkApi) => {
  const response = await api
    .put<ICreateCompanyDto, string>('Company/Create', data)
    .catch(handleError(thunkApi));
  return response;
});

export const updateCompany = createAsyncThunk<
  string,
  IUpdateCompanyDto,
  { rejectValue: IError }
>('Company/Update', async (data: IUpdateCompanyDto, thunkApi) => {
  const response = await api
    .post<IUpdateCompanyDto, string>('Company/Update', data)
    .catch(handleError(thunkApi));
  return response;
});
