import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { SnackbarOrigin } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { store } from './app/store';

import { ALERT_AUTO_HIDE_DURATION, ALERT_POSITION } from './constants/general';

import { theme } from './style/theme';

import './index.css';

import SentryHelpers from './helpers/SentryHelpers';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';

SentryHelpers.init();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider
          autoHideDuration={ALERT_AUTO_HIDE_DURATION}
          anchorOrigin={ALERT_POSITION as SnackbarOrigin}
        >
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
