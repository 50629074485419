import { IconButton } from '@mui/material';
import { styled as materialStyled } from '@mui/material/styles';

import { baseWhite, borderGray } from '../../style/colors';

export interface CustomIconButtonProps {
  dataTestId?: string;
  className?: string;
  child?: React.ReactNode;
  onClick(): void;
}

export const StyledIconButton = materialStyled(IconButton)(() => ({
  border: `1px solid ${borderGray}`,
  borderRadius: '4px',
  height: '100%',
  padding: '7px',
  marginLeft: '2px',
  backgroundColor: baseWhite,
}));

export function CustomIconButton({ child, ...props }: CustomIconButtonProps) {
  return <StyledIconButton {...props}>{child}</StyledIconButton>;
}
