import { createAsyncThunk } from '@reduxjs/toolkit';

import { IError } from '../../types/shared/error';

import * as api from '../../../api/api';
import IChangeDisableStatus from '../../types/employee/change_disable_status';
import {
  ICreateEmployeeDto,
  IEmployeeDto,
  IUpdateEmployeeDto,
} from '../../types/employee/employee_dto';
import handleError from '../../utils/handleError';

export const EMPLOYEE = {
  GET_EMPLOYEES_LIST: 'Employee/GetEmployeesFromCompany',
  CREATE: 'Employee/Create',
  UPDATE: 'Employee/Update',
  DELETE: 'Employee/Delete',
  SEND_VERIFICATION_EMAIL: 'Employee/SendVerificationEmail',
  RESET_PASSWORD: 'Employee/ResetEmployeePassword',
  GET_DETAILS: 'Employee/GetDetails',
  CHANGE_DISABLED_STATUS: 'Employee/ChangeDisableStatus',
}

export const getEmployeeListFromCompany = createAsyncThunk<IEmployeeDto[], string, { rejectValue: IError }>(
  EMPLOYEE.GET_EMPLOYEES_LIST,
  async (companyId, thunkApi) => {
    const path = `${EMPLOYEE.GET_EMPLOYEES_LIST}?companyId=${companyId}`;

    return await api
      .get<IEmployeeDto[]>(path)
      .catch(handleError(thunkApi));
  }
)

export const createEmployee = createAsyncThunk<
  string,
  ICreateEmployeeDto,
  { rejectValue: IError }
>(EMPLOYEE.CREATE, async (body: ICreateEmployeeDto, thunkApi) =>
    await api
      .put<ICreateEmployeeDto, string>(EMPLOYEE.CREATE, body)
      .catch(handleError(thunkApi))
);

export const getEmployee = createAsyncThunk<IEmployeeDto, string, { rejectValue: IError }>(
  EMPLOYEE.GET_DETAILS,
  async (employeeId, thunkApi) => {
    const path = `${EMPLOYEE.GET_DETAILS}?id=${employeeId}`;

    return await api
      .get<IEmployeeDto>(path)
      .catch(handleError(thunkApi));
  }
)

export const updateEmployee = createAsyncThunk<string, IUpdateEmployeeDto, { rejectValue: IError }>(
  EMPLOYEE.UPDATE,
  async (data: IUpdateEmployeeDto, thunkApi) =>
    await api
      .post<IUpdateEmployeeDto, string>(EMPLOYEE.UPDATE, data)
      .catch(handleError(thunkApi))
)

export const changeDisableStatus = createAsyncThunk<string, IChangeDisableStatus, { rejectValue: IError }>(
  EMPLOYEE.CHANGE_DISABLED_STATUS,
  async (data: IChangeDisableStatus, thunkApi) => {
    const path = `${EMPLOYEE.CHANGE_DISABLED_STATUS}?id=${data.employeeId}&disabled=${data.isDisabled}`;
    
    return await api
      .post<object, string>(path, {})
      .catch(handleError(thunkApi));
  }
)

export const resetPassword = createAsyncThunk<string, string, { rejectValue: IError }>(
  EMPLOYEE.RESET_PASSWORD,
  async (employeeId: string, thunkApi) => {
    const path = `${EMPLOYEE.RESET_PASSWORD}?id=${employeeId}`;

    return await api
      .post<object, string>(path, {})
      .catch(handleError(thunkApi));
  }
)

export const removeEmployee = createAsyncThunk<
  string,
  string,
  { rejectValue: IError }
>(EMPLOYEE.DELETE, async (employeeId, thunkApi) => {
  const path = `${EMPLOYEE.DELETE}?id=${employeeId}`;
  
  return await api
    .remove<string>(path)
    .catch(handleError(thunkApi));
});
