export const backgroundGrey = '#F0F0F0'
export const backgroundGreyLight = '#FBFBFB'
export const backgroundGreyDark = '#D2D2D2'
export const baseWhite = '#ffffff'
export const mainRed = '#CC0000'
export const textBlack = '#4B4849'
export const textBasic = '#363334'
export const backgroundBlack = '#1B1C1D'
export const borderGray = '#E1E1E1'
export const iconsGray = '#939094'
export const snackBarSuccess = '#69BE79'
export const snackBarError = '#D61E2C'