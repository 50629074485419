import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import { ReactNode } from 'react'
import { Row, RowVariant } from './row'

export interface CustomButtonProps {
  label: string
  onClick?(): void
  variant?: ButtonVariant
  loading?: boolean
  type?: ButtonType
  className?: string
  startIcon?: ReactNode
  endIcon?: ReactNode
  dataTestId?: string
  wrapperVariant?: RowVariant
  textCenter?: boolean
  disabled?: boolean
}

export interface StyledButtonProps {
  textCenter?: boolean
}

export type ButtonVariant = 'contained' | 'text' | 'outlined';
export type ButtonType = 'button' | 'submit';

const StyledLoadingButton = styled(LoadingButton)((props: StyledButtonProps) =>
  props.textCenter
    ? {
        lineHeight: 1,
      }
    : {}
);

export default function CustomButton({
  label,
  dataTestId,
  onClick,
  variant = 'contained',
  loading = false,
  type = 'button',
  className,
  wrapperVariant = 'start',
  ...props
}: CustomButtonProps) {
  return (
    <Row variant={wrapperVariant}>
      <StyledLoadingButton
        {...props}
        data-testid={dataTestId}
        type={type}
        loading={loading}
        variant={variant}
        color='primary'
        size='small'
        onClick={onClick ? () => onClick() : undefined}
        className={className}
      >
        {label}
      </StyledLoadingButton>
    </Row>
  );
}
