import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks'

import { createCompany } from '../../features/company_table/services/company_service'
import {
  getCreateCompanyDto,
  getCreateCompanyStatus,
} from '../../features/company_table/slices/createCompanySlice'
import { StatusEnum } from '../../features/types/shared/status'

import { MainContainer } from '../shared/main_container'

import { useNotification } from '../../hooks/useNotification';
import { routesDefinitions } from '../../routes';
import NavRow from './nav_row';
import TabNav from './tab_nav';

interface CreateCompanyProps {
  dataTestId: string;
}

export default function CreateCompany({ dataTestId }: CreateCompanyProps) {
  const createCompanyDto = useAppSelector(getCreateCompanyDto)
  const createCompanyStatus = useAppSelector(getCreateCompanyStatus)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotification();

  const [isSaveAndBackButtonClicked, setIsSaveAndBackButtonClicked] =
    useState<boolean>(false);

  const handleCreateCompany = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      try {
        const companyId = await dispatch(
          createCompany({
            ...createCompanyDto,
          })
        ).unwrap();
  
        if (companyId) {
          notifySuccess(t('company_options.created_successfully'));

          const path = isSaveAndBackButtonClicked
            ? routesDefinitions.dashboard
            : `/company/${companyId}`;

          navigate(path);
        }
  
      } catch(err: unknown) {
        notifyError(t('error.common_error'));
        console.error(err);
      }
    },
    [
      createCompanyDto,
      dispatch,
      isSaveAndBackButtonClicked,
      navigate,
      notifySuccess,
      notifyError,
      t,
    ]
  );

  return (
    <MainContainer>
      <form data-testid={dataTestId} onSubmit={handleCreateCompany}>
        <TabNav companyId='' />
        <NavRow
          handleOnClick={setIsSaveAndBackButtonClicked}
          isLoading={createCompanyStatus === StatusEnum.PENDING}
          goBackRoute={routesDefinitions.dashboard}
        />
      </form>
    </MainContainer>
  )
}
