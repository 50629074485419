import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getCompany, updateCompany } from '../../features/company_table/services/company_service'
import {
  getCompanyDto,
  updateCompanyDtoStatus,
} from '../../features/company_table/slices/getAndUpdateCompanySlice'
import { StatusEnum } from '../../features/types/shared/status'
import { routesDefinitions } from '../../routes'
import { MainContainer } from '../shared/main_container'
import NavRow from './nav_row'
import TabNav from './tab_nav'
import { useNotification } from '../../hooks/useNotification';
import { useTranslation } from 'react-i18next';

export default function EditCompany() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { companyId } = useParams()
  const companyDto = useAppSelector(getCompanyDto)
  const updateStatus = useAppSelector(updateCompanyDtoStatus)
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotification();

  const [isSaveAndBackButtonClicked, setIsSaveAndBackButtonClicked] =
    useState<boolean>(false);

  useEffect(() => {
    if(companyId) {
      dispatch(getCompany(companyId));
    }
  }, [companyId, dispatch])

  const handleUpdateCompany = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      try {
        const companyId = await dispatch(
          updateCompany({
            id: companyDto?.id,
            name: companyDto?.name,
            externalId: companyDto?.externalId,
          })
        ).unwrap();
  
        if (companyId) {
          notifySuccess(t('company_options.updated_successfully'));
          isSaveAndBackButtonClicked && navigate(routesDefinitions.dashboard);
        }
  
      } catch(err: unknown) {
        notifyError(t('error.common_error'));
        console.error(err);
      }
    },
    [
      dispatch,
      companyDto,
      isSaveAndBackButtonClicked,
      notifySuccess,
      t,
      navigate,
      notifyError
    ]
  );

  return (
    <MainContainer>
      <form onSubmit={handleUpdateCompany}>
        <TabNav companyId={companyId ?? ''} />
        <NavRow
          handleOnClick={setIsSaveAndBackButtonClicked}
          isLoading={updateStatus === StatusEnum.PENDING}
          goBackRoute={routesDefinitions.dashboard}
        />
      </form>
    </MainContainer>
  )
}
