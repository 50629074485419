export default class DateHelpers {
  public static defaultDate = new Date('01 01 1970 01:00')

  public static dateTypeToHumanReadableConverter(dateTime?: Date): string | null {
    if (!dateTime) {
      return null;
    }

    const date = new Date(dateTime)
    if (this.isDateDefault(date)) {
      return '-'
    }
    const dateHumanReadable = this.getDateHumanReadableFromDate(date)
    const timeHumanReadable = this.getTimeHumanReadableFromDate(date)
    return dateHumanReadable + ', ' + timeHumanReadable
  }

  private static getDateHumanReadableFromDate(date: Date): string {
    const year = date.getFullYear()
    const month = this.getMonthInHumanReadable(date.getUTCMonth())
    const day = date.getUTCDate()
    return day + '/' + month + '/' + year
  }

  private static getMonthInHumanReadable(month: number): string {
    const monthInHumanReadable: string = (month + 1).toString()
    return monthInHumanReadable
  }

  private static getTimeHumanReadableFromDate(date: Date): string {
    const hours = date.getHours()
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
    const sec = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
    return hours + ':' + minutes + ':' + sec
  }

  private static isDateDefault(date: Date): boolean {
    return new Date(date).getTime() === this.defaultDate.getTime()
  }
}
