import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import ICreateEmployeeState from '../../types/employee/create_employee_state'
import { StatusEnum } from '../../types/shared/status'
import { createEmployee } from '../services/employee_service'
import { SELECT_LANGUAGE_OPTIONS } from '../../../constants/general'
import { ICreateEmployeeDto } from '../../types/employee/employee_dto'

export const emptyCreateEmployeeDto: ICreateEmployeeDto = {
  companyId: '',
  firstName: '',
  lastName: '',
  namePrefix: '',
  email: '',
  language: SELECT_LANGUAGE_OPTIONS[0].value,
}

export const initialState: ICreateEmployeeState = {
  status: StatusEnum.INITIAL,
  hasCompanyId: false,
  createEmployeeDto: emptyCreateEmployeeDto,
  error: { message: '' },
}

export const createEmployeeSlice = createSlice({
  name: 'createEmployee',
  initialState,
  reducers: {
    handleCompanyIdChangeForEmployeeCreate(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.companyId = action.payload
      state.hasCompanyId = true
    },
    handleCreateEmployeeFormValidationError(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.error.message = action.payload
    },
    handleCreateEmployeeFirstNameChange(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.firstName = action.payload
    },
    handleCreateEmployeeNamePrefixChange(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.namePrefix = action.payload
    },
    handleCreateEmployeeLastNameChange(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.lastName = action.payload
    },
    handleCreateEmployeeEmailChange(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.email = action.payload
    },
    handleCreateEmployeeLanguageSelect(state: ICreateEmployeeState, action: PayloadAction<string>) {
      state.createEmployeeDto.language = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createEmployee.fulfilled, (state: ICreateEmployeeState) => {
      state.status = StatusEnum.LOADED
      state.createEmployeeDto = emptyCreateEmployeeDto
      state.error.message = ''
    })
    builder.addCase(createEmployee.pending, (state: ICreateEmployeeState) => {
      state.status = StatusEnum.PENDING
      state.error.message = ''
    })
    builder.addCase(createEmployee.rejected, (state, { payload }) => {
      state.status = StatusEnum.ERROR
      state.error = payload || initialState.error;
    })
  },
})

export const getCreateEmployeeDto = (state: RootState) => state.createEmployee.createEmployeeDto
export const getHasCompanyId = (state: RootState) => state.createEmployee.hasCompanyId
export const getCreateEmployeeStatus = (state: RootState) => state.createEmployee.status
export const getCreateEmployeeError = ({ createEmployee }: RootState) => createEmployee.error

export const {
  handleCompanyIdChangeForEmployeeCreate,
  handleCreateEmployeeFormValidationError,
  handleCreateEmployeeFirstNameChange,
  handleCreateEmployeeNamePrefixChange,
  handleCreateEmployeeLastNameChange,
  handleCreateEmployeeEmailChange,
  handleCreateEmployeeLanguageSelect,
} = createEmployeeSlice.actions
export default createEmployeeSlice.reducer
