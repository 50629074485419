import { useAppTranslation } from './useAppTranslation';

import { IError } from '../features/types/shared/error';

import { ErrorCode, HttpStatusCode } from '../constants/general';

export default function useErrorMessage({
  status,
  message,
  errorCode,
}: IError) {
  const { t } = useAppTranslation('error');

  if (status === HttpStatusCode.UNAUTHORIZED) {
    return t('unauthorized');
  }

  if (
    status === HttpStatusCode.CONFLICT &&
    errorCode === ErrorCode.EMAIL_TAKEN
  ) {
    return t('email_is_taken');
  }

  return message;
}
