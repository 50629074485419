import { IError } from '../types/shared/error';
import { ThunkApiType, ResponseBody } from '../types/shared/general';

const handleError = (thunkApi: ThunkApiType) => (err: ResponseBody) => {
  const { message, status } = err;
  const e: IError = {
    message,
    status,
  };

  return thunkApi.rejectWithValue(e);
};

export default handleError;
